// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dvs-js": () => import("./../../../src/pages/dvs.js" /* webpackChunkName: "component---src-pages-dvs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-post-jsx": () => import("./../../../src/templates/Post/Post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-templates-work-work-jsx": () => import("./../../../src/templates/Work/Work.jsx" /* webpackChunkName: "component---src-templates-work-work-jsx" */)
}

