import ReactGA from 'react-ga';
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// gatsby-browser.js
// require("prismjs/themes/prism-tomorrow.css")
// require("../src/styles/prism-base16-ateliersulphurpool.light.css")

ReactGA.initialize("UA-52814451-2");