// import React, { Component } from "react";

const baseUrl = "https://twemoji.maxcdn.com/svg/"
const emoji = {
    "exclamation question mark": "2049",
    "trade mark sign": "2122",
    "information source": "2139",
    "left right arrow": "2194",
    "up down arrow": "2195",
    "north west arrow": "2196",
    "north east arrow": "2197",
    "south east arrow": "2198",
    "south west arrow": "2199",
    "black sun with rays": "2600",
    "cloud": "2601",
    "ballot box with check": "2611",
    "umbrella with rain drops": "2614",
    "hot beverage": "2615",
    "aries": "2648",
    "taurus": "2649",
    "sagittarius": "2650",
    "capricorn": "2651",
    "aquarius": "2652",
    "pisces": "2653",
    "black spade suit": "2660",
    "black club suit": "2663",
    "black heart suit": "2665",
    "black diamond suit": "2666",
    "hot springs": "2668",
    "anchor": "2693",
    "black scissors": "2702",
    "white heavy check mark": "2705",
    "airplane": "2708",
    "envelope": "2709",
    "black nib": "2712",
    "heavy check mark": "2714",
    "heavy multiplication x": "2716",
    "sparkles": "2728",
    "eight spoked asterisk": "2733",
    "eight pointed black star": "2734",
    "snowflake": "2744",
    "sparkle": "2747",
    "black question mark ornament": "2753",
    "white question mark ornament": "2754",
    "white exclamation mark ornament": "2755",
    "heavy exclamation mark symbol": "2757",
    "heavy black heart": "2764",
    "heavy plus sign": "2795",
    "heavy minus sign": "2796",
    "heavy division sign": "2797",
    "arrow pointing rightwards then curving upwards": "2934",
    "arrow pointing rightwards then curving downwards": "2935",
    "wavy dash": "3030",
    "circled ideograph congratulation": "3297",
    "circled ideograph secret": "3299",
    "large red circle": "1f534",
    "white square button": "1f533",
    "large orange diamond": "1f536",
    "large blue circle": "1f535",
    "small orange diamond": "1f538",
    "large blue diamond": "1f537",
    "small blue diamond": "1f539",
    "hocho": "1f52a",
    "microscope": "1f52c",
    "pistol": "1f52b",
    "crystal ball": "1f52e",
    "telescope": "1f52d",
    "six pointed star with middle dot": "1f52f",
    "foggy": "1f301",
    "cyclone": "1f300",
    "input symbol for symbols": "1f523",
    "input symbol for numbers": "1f522",
    "fire": "1f525",
    "input symbol for latin letters": "1f524",
    "wrench": "1f527",
    "electric torch": "1f526",
    "nut and bolt": "1f529",
    "hammer": "1f528",
    "on with exclamation mark with left right arrow above": "1f51b",
    "end with leftwards arrow above": "1f51a",
    "top with upwards arrow above": "1f51d",
    "soon with rightwards arrow above": "1f51c",
    "keycap ten": "1f51f",
    "no one under eighteen symbol": "1f51e",
    "japanese symbol for beginner": "1f530",
    "black square button": "1f532",
    "trident emblem": "1f531",
    "lock": "1f512",
    "key": "1f511",
    "bell": "1f514",
    "open lock": "1f513",
    "bookmark": "1f516",
    "hourglass": "231b",
    "bell with cancellation stroke": "1f515",
    "watch": "231a",
    "radio button": "1f518",
    "link symbol": "1f517",
    "back with leftwards arrow above": "1f519",
    "speaker with three sound waves": "1f50a",
    "electric plug": "1f50c",
    "battery": "1f50b",
    "right-pointing magnifying glass": "1f50e",
    "left-pointing magnifying glass": "1f50d",
    "lock with ink pen": "1f50f",
    "digit five + combining enclosing keycap": "35-20e3",
    "input symbol for latin small letters": "1f521",
    "input symbol for latin capital letters": "1f520",
    "clockwise rightwards and leftwards open circle arrows": "1f501",
    "twisted rightwards arrows": "1f500",
    "clockwise downwards and upwards open circle arrows": "1f503",
    "clockwise rightwards and leftwards open circle arrows with circled one overlay": "1f502",
    "low brightness symbol": "1f505",
    "anticlockwise downwards and upwards open circle arrows": "1f504",
    "speaker with cancellation stroke": "1f507",
    "high brightness symbol": "1f506",
    "speaker with one sound wave": "1f509",
    "leftwards black arrow": "2b05",
    "upwards black arrow": "2b06",
    "downwards black arrow": "2b07",
    "closed lock with key": "1f510",
    "f + r": "1f1eb-1f1f7",
    "black large square": "2b1b",
    "white large square": "2b1c",
    "tropical drink": "1f379",
    "heavy large circle": "2b55",
    "cookie": "1f36a",
    "candy": "1f36c",
    "chocolate bar": "1f36b",
    "custard": "1f36e",
    "lollipop": "1f36d",
    "honey pot": "1f36f",
    "white medium star": "2b50",
    "wrapped present": "1f381",
    "ribbon": "1f380",
    "jack-o-lantern": "1f383",
    "birthday cake": "1f382",
    "father christmas": "1f385",
    "christmas tree": "1f384",
    "firework sparkler": "1f387",
    "fireworks": "1f386",
    "party popper": "1f389",
    "balloon": "1f388",
    "digit four + combining enclosing keycap": "34-20e3",
    "doughnut": "1f369",
    "ice cream": "1f368",
    "high voltage sign": "26a1",
    "warning sign": "26a0",
    "curry and rice": "1f35b",
    "cooked rice": "1f35a",
    "spaghetti": "1f35d",
    "steaming bowl": "1f35c",
    "french fries": "1f35f",
    "bread": "1f35e",
    "shortcake": "1f370",
    "pot of food": "1f372",
    "bento box": "1f371",
    "fork and knife": "1f374",
    "cooking": "1f373",
    "sake bottle and cup": "1f376",
    "teacup without handle": "1f375",
    "cocktail glass": "1f378",
    "wine glass": "1f377",
    "rice cracker": "1f358",
    "poultry leg": "1f357",
    "rice ball": "1f359",
    "tangerine": "1f34a",
    "banana": "1f34c",
    "lemon": "1f34b",
    "red apple": "1f34e",
    "pineapple": "1f34d",
    "green apple": "1f34f",
    "dango": "1f361",
    "roasted sweet potato": "1f360",
    "sushi": "1f363",
    "oden": "1f362",
    "fish cake with swirl design": "1f365",
    "fried shrimp": "1f364",
    "shaved ice": "1f367",
    "soft ice cream": "1f366",
    "grapes": "1f347",
    "sun behind cloud": "26c5",
    "aubergine": "1f346",
    "snowman without snow": "26c4",
    "watermelon": "1f349",
    "melon": "1f348",
    "sunflower": "1f33b",
    "hibiscus": "1f33a",
    "ear of maize": "1f33d",
    "blossom": "1f33c",
    "herb": "1f33f",
    "ear of rice": "1f33e",
    "medium black circle": "26ab",
    "medium white circle": "26aa",
    "pear": "1f350",
    "cherries": "1f352",
    "peach": "1f351",
    "hamburger": "1f354",
    "strawberry": "1f353",
    "meat on bone": "1f356",
    "slice of pizza": "1f355",
    "cactus": "1f335",
    "cherry blossom": "1f338",
    "no entry": "26d4",
    "tulip": "1f337",
    "rose": "1f339",
    "baseball": "26be",
    "soccer ball": "26bd",
    "maple leaf": "1f341",
    "four leaf clover": "1f340",
    "leaf fluttering in wind": "1f343",
    "fallen leaf": "1f342",
    "tomato": "1f345",
    "mushroom": "1f344",
    "clock face twelve-thirty": "1f567",
    "clock face eleven-thirty": "1f566",
    "clock face twelve oclock": "1f55b",
    "clock face eleven oclock": "1f55a",
    "first quarter moon with face": "1f31b",
    "clock face two-thirty": "1f55d",
    "new moon with face": "1f31a",
    "clock face one-thirty": "1f55c",
    "full moon with face": "1f31d",
    "clock face four-thirty": "1f55f",
    "ophiuchus": "26ce",
    "last quarter moon with face": "1f31c",
    "clock face three-thirty": "1f55e",
    "glowing star": "1f31f",
    "sun with face": "1f31e",
    "part alternation mark": "303d",
    "chestnut": "1f330",
    "evergreen tree": "1f332",
    "seedling": "1f331",
    "palm tree": "1f334",
    "deciduous tree": "1f333",
    "waxing gibbous moon symbol": "1f314",
    "clock face seven oclock": "1f556",
    "first quarter moon symbol": "1f313",
    "clock face six oclock": "1f555",
    "clock face nine oclock": "1f558",
    "waning gibbous moon symbol": "1f316",
    "full moon symbol": "1f315",
    "clock face eight oclock": "1f557",
    "sailboat": "26f5",
    "waning crescent moon symbol": "1f318",
    "clock face ten oclock": "1f559",
    "last quarter moon symbol": "1f317",
    "flag in hole": "26f3",
    "fountain": "26f2",
    "crescent moon": "1f319",
    "water wave": "1f30a",
    "milky way": "1f30c",
    "volcano": "1f30b",
    "earth globe americas": "1f30e",
    "earth globe europe-africa": "1f30d",
    "earth globe asia-australia": "1f30f",
    "clock face six-thirty": "1f561",
    "clock face five-thirty": "1f560",
    "clock face eight-thirty": "1f563",
    "shooting star": "1f320",
    "clock face seven-thirty": "1f562",
    "clock face ten-thirty": "1f565",
    "clock face nine-thirty": "1f564",
    "night with stars": "1f303",
    "closed umbrella": "1f302",
    "sunrise": "1f305",
    "sunrise over mountains": "1f304",
    "sunset over buildings": "1f307",
    "cityscape at dusk": "1f306",
    "circled latin capital letter m": "24c2",
    "bridge at night": "1f309",
    "rainbow": "1f308",
    "tent": "26fa",
    "copyright sign": "a9",
    "down-pointing red triangle": "1f53b",
    "up-pointing red triangle": "1f53a",
    "down-pointing small red triangle": "1f53d",
    "up-pointing small red triangle": "1f53c",
    "digit six + combining enclosing keycap": "36-20e3",
    "church": "26ea",
    "clock face one oclock": "1f550",
    "clock face three oclock": "1f552",
    "globe with meridians": "1f310",
    "clock face two oclock": "1f551",
    "clock face five oclock": "1f554",
    "waxing crescent moon symbol": "1f312",
    "new moon symbol": "1f311",
    "clock face four oclock": "1f553",
    "carousel horse": "1f3a0",
    "roller coaster": "1f3a2",
    "ferris wheel": "1f3a1",
    "microphone": "1f3a4",
    "fishing pole and fish": "1f3a3",
    "cinema": "1f3a6",
    "movie camera": "1f3a5",
    "artist palette": "1f3a8",
    "fuel pump": "26fd",
    "headphone": "1f3a7",
    "top hat": "1f3a9",
    "registered sign": "ae",
    "g + b": "1f1ec-1f1e7",
    "green heart": "1f49a",
    "purple heart": "1f49c",
    "yellow heart": "1f49b",
    "revolving hearts": "1f49e",
    "heart with ribbon": "1f49d",
    "heart decoration": "1f49f",
    "e + s": "1f1ea-1f1f8",
    "d + e": "1f1e9-1f1ea",
    "digit two + combining enclosing keycap": "32-20e3",
    "j + p": "1f1ef-1f1f5",
    "i + t": "1f1ee-1f1f9",
    "european castle": "1f3f0",
    "black telephone": "260e",
    "school": "1f3eb",
    "convenience store": "1f3ea",
    "factory": "1f3ed",
    "department store": "1f3ec",
    "japanese castle": "1f3ef",
    "izakaya lantern": "1f3ee",
    "white up pointing index": "261d",
    "house building": "1f3e0",
    "office building": "1f3e2",
    "house with garden": "1f3e1",
    "european post office": "1f3e4",
    "japanese post office": "1f3e3",
    "bank": "1f3e6",
    "hospital": "1f3e5",
    "hotel": "1f3e8",
    "automated teller machine": "1f3e7",
    "love hotel": "1f3e9",
    "tennis racquet and ball": "1f3be",
    "running shirt with sash": "1f3bd",
    "moyai": "1f5ff",
    "ski and ski boot": "1f3bf",
    "swimmer": "1f3ca",
    "performing arts": "1f3ad",
    "clapper board": "1f3ac",
    "direct hit": "1f3af",
    "video game": "1f3ae",
    "white smiling face": "263a",
    "basketball and hoop": "1f3c0",
    "snowboarder": "1f3c2",
    "chequered flag": "1f3c1",
    "surfer": "1f3c4",
    "runner": "1f3c3",
    "trophy": "1f3c6",
    "american football": "1f3c8",
    "horse racing": "1f3c7",
    "rugby football": "1f3c9",
    "trumpet": "1f3ba",
    "tokyo tower": "1f5fc",
    "mount fuji": "1f5fb",
    "musical score": "1f3bc",
    "silhouette of japan": "1f5fe",
    "violin": "1f3bb",
    "statue of liberty": "1f5fd",
    "scorpius": "264f",
    "libra": "264e",
    "virgo": "264d",
    "leo": "264c",
    "cancer": "264b",
    "gemini": "264a",
    "billiards": "1f3b1",
    "slot machine": "1f3b0",
    "bowling": "1f3b3",
    "game die": "1f3b2",
    "musical note": "1f3b5",
    "flower playing cards": "1f3b4",
    "saxophone": "1f3b7",
    "multiple musical notes": "1f3b6",
    "musical keyboard": "1f3b9",
    "guitar": "1f3b8",
    "ticket": "1f3ab",
    "circus tent": "1f3aa",
    "rooster": "1f413",
    "monkey": "1f412",
    "dog": "1f415",
    "chicken": "1f414",
    "boar": "1f417",
    "pig": "1f416",
    "octopus": "1f419",
    "elephant": "1f418",
    "happy person raising one hand": "1f64b",
    "speak-no-evil monkey": "1f64a",
    "person frowning": "1f64d",
    "whale": "1f40b",
    "hands raised": "1f64c",
    "crocodile": "1f40a",
    "person with folded hands": "1f64f",
    "snake": "1f40d",
    "person with pouting face": "1f64e",
    "snail": "1f40c",
    "ram": "1f40f",
    "horse": "1f40e",
    "tropical fish": "1f420",
    "turtle": "1f422",
    "blowfish": "1f421",
    "ox": "1f402",
    "digit one + combining enclosing keycap": "31-20e3",
    "mouse": "1f401",
    "face with ok gesture": "1f646",
    "cow": "1f404",
    "face with no good gesture": "1f645",
    "water buffalo": "1f403",
    "see-no-evil monkey": "1f648",
    "leopard": "1f406",
    "person bowing deeply": "1f647",
    "tiger": "1f405",
    "cat": "1f408",
    "hear-no-evil monkey": "1f649",
    "rabbit": "1f407",
    "dragon": "1f409",
    "smiling cat face with open mouth": "1f63a",
    "cat face with wry smile": "1f63c",
    "smiling cat face with heart-shaped eyes": "1f63b",
    "pouting cat face": "1f63e",
    "kissing cat face with closed eyes": "1f63d",
    "crying cat face": "1f63f",
    "sheep": "1f411",
    "goat": "1f410",
    "flushed face": "1f633",
    "astonished face": "1f632",
    "dizzy face": "1f635",
    "sleeping face": "1f634",
    "face with medical mask": "1f637",
    "wheelchair symbol": "267f",
    "face without mouth": "1f636",
    "cat face with tears of joy": "1f639",
    "grinning cat face with smiling eyes": "1f638",
    "tired face": "1f62b",
    "sleepy face": "1f62a",
    "loudly crying face": "1f62d",
    "grimacing face": "1f62c",
    "hushed face": "1f62f",
    "face with open mouth": "1f62e",
    "black universal recycling symbol": "267b",
    "weary cat face": "1f640",
    "rat": "1f400",
    "crying face": "1f622",
    "pouting face": "1f621",
    "face with look of triumph": "1f624",
    "persevering face": "1f623",
    "frowning face with open mouth": "1f626",
    "disappointed but relieved face": "1f625",
    "fearful face": "1f628",
    "anguished face": "1f627",
    "weary face": "1f629",
    "kissing face with closed eyes": "1f61a",
    "face with stuck-out tongue and winking eye": "1f61c",
    "face with stuck-out tongue": "1f61b",
    "disappointed face": "1f61e",
    "face with stuck-out tongue and tightly-closed eyes": "1f61d",
    "worried face": "1f61f",
    "face screaming in fear": "1f631",
    "face with open mouth and cold sweat": "1f630",
    "expressionless face": "1f611",
    "neutral face": "1f610",
    "face with cold sweat": "1f613",
    "unamused face": "1f612",
    "confused face": "1f615",
    "pensive face": "1f614",
    "kissing face": "1f617",
    "confounded face": "1f616",
    "kissing face with smiling eyes": "1f619",
    "face throwing a kiss": "1f618",
    "r + u": "1f1f7-1f1fa",
    "face savouring delicious food": "1f60b",
    "smiling face with smiling eyes": "1f60a",
    "smiling face with heart-shaped eyes": "1f60d",
    "relieved face": "1f60c",
    "smirking face": "1f60f",
    "smiling face with sunglasses": "1f60e",
    "digit eight + combining enclosing keycap": "38-20e3",
    "angry face": "1f620",
    "grinning face": "1f600",
    "face with tears of joy": "1f602",
    "grinning face with smiling eyes": "1f601",
    "smiling face with open mouth and smiling eyes": "1f604",
    "smiling face with open mouth": "1f603",
    "number sign + combining enclosing keycap": "23-20e3",
    "smiling face with open mouth and tightly-closed eyes": "1f606",
    "smiling face with open mouth and cold sweat": "1f605",
    "smiling face with horns": "1f608",
    "smiling face with halo": "1f607",
    "c + n": "1f1e8-1f1f3",
    "winking face": "1f609",
    "black rightwards arrow": "27a1",
    "digit nine + combining enclosing keycap": "39-20e3",
    "playing card black joker": "1f0cf",
    "curly loop": "27b0",
    "kiss mark": "1f48b",
    "pill": "1f48a",
    "ring": "1f48d",
    "love letter": "1f48c",
    "kiss": "1f48f",
    "gem stone": "1f48e",
    "syringe": "1f489",
    "japanese goblin": "1f47a",
    "squared cjk unified ideograph-55b6": "1f23a",
    "baby angel": "1f47c",
    "ghost": "1f47b",
    "alien monster": "1f47e",
    "extraterrestrial alien": "1f47d",
    "imp": "1f47f",
    "couple with heart": "1f491",
    "bouquet": "1f490",
    "circled ideograph accept": "1f251",
    "beating heart": "1f493",
    "circled ideograph advantage": "1f250",
    "wedding": "1f492",
    "two hearts": "1f495",
    "broken heart": "1f494",
    "growing heart": "1f497",
    "sparkling heart": "1f496",
    "blue heart": "1f499",
    "heart with arrow": "1f498",
    "squared cjk unified ideograph-6708": "1f237",
    "japanese ogre": "1f479",
    "squared cjk unified ideograph-6709": "1f236",
    "princess": "1f478",
    "squared cjk unified ideograph-5272": "1f239",
    "squared cjk unified ideograph-7533": "1f238",
    "man and woman holding hands": "1f46b",
    "family": "1f46a",
    "two women holding hands": "1f46d",
    "two men holding hands": "1f46c",
    "woman with bunny ears": "1f46f",
    "police officer": "1f46e",
    "squared cjk unified ideograph-6307": "1f22f",
    "skull": "1f480",
    "guardsman": "1f482",
    "double exclamation mark": "203c",
    "information desk person": "1f481",
    "lipstick": "1f484",
    "dancer": "1f483",
    "face massage": "1f486",
    "nail polish": "1f485",
    "mahjong tile red dragon": "1f004",
    "barber pole": "1f488",
    "haircut": "1f487",
    "man": "1f468",
    "girl": "1f467",
    "woman": "1f469",
    "delivery truck": "1f69a",
    "womans clothes": "1f45a",
    "tractor": "1f69c",
    "articulated lorry": "1f69b",
    "squared cjk unified ideograph-7121": "1f21a",
    "handbag": "1f45c",
    "mountain railway": "1f69e",
    "purse": "1f45b",
    "monorail": "1f69d",
    "mans shoe": "1f45e",
    "pouch": "1f45d",
    "suspension railway": "1f69f",
    "athletic shoe": "1f45f",
    "person with blond hair": "1f471",
    "bride with veil": "1f470",
    "man with turban": "1f473",
    "man with gua pi mao": "1f472",
    "squared cjk unified ideograph-7a7a": "1f233",
    "older woman": "1f475",
    "squared cjk unified ideograph-7981": "1f232",
    "older man": "1f474",
    "squared cjk unified ideograph-6e80": "1f235",
    "construction worker": "1f477",
    "squared cjk unified ideograph-5408": "1f234",
    "baby": "1f476",
    "recreational vehicle": "1f699",
    "black right-pointing triangle": "25b6",
    "dress": "1f457",
    "jeans": "1f456",
    "oncoming automobile": "1f698",
    "bikini": "1f459",
    "kimono": "1f458",
    "tram": "1f68a",
    "waving hand sign": "1f44b",
    "oncoming bus": "1f68d",
    "bus": "1f68c",
    "fisted hand sign": "1f44a",
    "bus stop": "1f68f",
    "thumbs up sign": "1f44d",
    "ok hand sign": "1f44c",
    "trolleybus": "1f68e",
    "clapping hands sign": "1f44f",
    "thumbs down sign": "1f44e",
    "high-heeled shoe": "1f460",
    "womans boots": "1f462",
    "womans sandal": "1f461",
    "bust in silhouette": "1f464",
    "footprints": "1f463",
    "boy": "1f466",
    "busts in silhouette": "1f465",
    "white up pointing backhand index": "1f446",
    "light rail": "1f688",
    "metro": "1f687",
    "tongue": "1f445",
    "white left pointing backhand index": "1f448",
    "station": "1f689",
    "white down pointing backhand index": "1f447",
    "white right pointing backhand index": "1f449",
    "black left-pointing triangle": "25c0",
    "wolf face": "1f43a",
    "panda face": "1f43c",
    "bear face": "1f43b",
    "paw prints": "1f43e",
    "pig nose": "1f43d",
    "white small square": "25ab",
    "black small square": "25aa",
    "ambulance": "1f691",
    "minibus": "1f690",
    "police car": "1f693",
    "crown": "1f451",
    "fire engine": "1f692",
    "open hands sign": "1f450",
    "taxi": "1f695",
    "eyeglasses": "1f453",
    "womans hat": "1f452",
    "oncoming police car": "1f694",
    "automobile": "1f697",
    "t-shirt": "1f455",
    "necktie": "1f454",
    "oncoming taxi": "1f696",
    "monkey face": "1f435",
    "horse face": "1f434",
    "pig face": "1f437",
    "dog face": "1f436",
    "hamster face": "1f439",
    "frog face": "1f438",
    "bactrian camel": "1f42b",
    "dromedary camel": "1f42a",
    "mouse face": "1f42d",
    "dolphin": "1f42c",
    "tiger face": "1f42f",
    "cow face": "1f42e",
    "rocket": "1f680",
    "eyes": "1f440",
    "steam locomotive": "1f682",
    "helicopter": "1f681",
    "high-speed train": "1f684",
    "ear": "1f442",
    "railway car": "1f683",
    "squared katakana sa": "1f202",
    "mouth": "1f444",
    "train": "1f686",
    "high-speed train with bullet nose": "1f685",
    "squared katakana koko": "1f201",
    "nose": "1f443",
    "baby chick": "1f424",
    "hatching chick": "1f423",
    "bird": "1f426",
    "front-facing baby chick": "1f425",
    "koala": "1f428",
    "penguin": "1f427",
    "poodle": "1f429",
    "spiral shell": "1f41a",
    "ant": "1f41c",
    "bug": "1f41b",
    "lady beetle": "1f41e",
    "honeybee": "1f41d",
    "fish": "1f41f",
    "cat face": "1f431",
    "rabbit face": "1f430",
    "spouting whale": "1f433",
    "dragon face": "1f432",
    "speech balloon": "1f4ac",
    "dizzy symbol": "1f4ab",
    "white flower": "1f4ae",
    "thought balloon": "1f4ad",
    "hundred points symbol": "1f4af",
    "file folder": "1f4c1",
    "dvd": "1f4c0",
    "page with curl": "1f4c3",
    "open file folder": "1f4c2",
    "calendar": "1f4c5",
    "page facing up": "1f4c4",
    "card index": "1f4c7",
    "tear-off calendar": "1f4c6",
    "chart with downwards trend": "1f4c9",
    "chart with upwards trend": "1f4c8",
    "personal computer": "1f4bb",
    "seat": "1f4ba",
    "squared vs": "1f19a",
    "white medium square": "25fb",
    "money bag": "1f4b0",
    "heavy dollar sign": "1f4b2",
    "currency exchange": "1f4b1",
    "banknote with yen sign": "1f4b4",
    "credit card": "1f4b3",
    "banknote with euro sign": "1f4b6",
    "banknote with dollar sign": "1f4b5",
    "money with wings": "1f4b8",
    "banknote with pound sign": "1f4b7",
    "chart with upwards trend and yen sign": "1f4b9",
    "u + s": "1f1fa-1f1f8",
    "flexed biceps": "1f4aa",
    "negative squared ab": "1f18e",
    "electric light bulb": "1f4a1",
    "diamond shape with a dot inside": "1f4a0",
    "bomb": "1f4a3",
    "anger symbol": "1f4a2",
    "collision symbol": "1f4a5",
    "sleeping symbol": "1f4a4",
    "droplet": "1f4a7",
    "black medium small square": "25fe",
    "splashing sweat symbol": "1f4a6",
    "white medium small square": "25fd",
    "poop": "1f4a9",
    "black medium square": "25fc",
    "dash symbol": "1f4a8",
    "restroom": "1f6bb",
    "leftwards arrow with hook": "21a9",
    "womens symbol": "1f6ba",
    "toilet": "1f6bd",
    "black right-pointing double triangle": "23e9",
    "baby symbol": "1f6bc",
    "shower": "1f6bf",
    "water closet": "1f6be",
    "negative squared latin capital letter p": "1f17f",
    "negative squared latin capital letter o": "1f17e",
    "squared cool": "1f192",
    "squared cl": "1f191",
    "squared id": "1f194",
    "squared free": "1f193",
    "squared ng": "1f196",
    "squared new": "1f195",
    "squared sos": "1f198",
    "squared ok": "1f197",
    "squared up with exclamation mark": "1f199",
    "door": "1f6aa",
    "smoking symbol": "1f6ac",
    "no entry sign": "1f6ab",
    "put litter in its place symbol": "1f6ae",
    "no smoking symbol": "1f6ad",
    "do not litter symbol": "1f6af",
    "rightwards arrow with hook": "21aa",
    "black down-pointing double triangle": "23ec",
    "black up-pointing double triangle": "23eb",
    "black left-pointing double triangle": "23ea",
    "bathtub": "1f6c1",
    "bath": "1f6c0",
    "customs": "1f6c3",
    "passport control": "1f6c2",
    "left luggage": "1f6c5",
    "baggage claim": "1f6c4",
    "hourglass with flowing sand": "23f3",
    "alarm clock": "23f0",
    "potable water symbol": "1f6b0",
    "bicycle": "1f6b2",
    "non-potable water symbol": "1f6b1",
    "bicyclist": "1f6b4",
    "no bicycles": "1f6b3",
    "pedestrian": "1f6b6",
    "negative squared latin capital letter a": "1f170",
    "mountain bicyclist": "1f6b5",
    "children crossing": "1f6b8",
    "negative squared latin capital letter b": "1f171",
    "no pedestrians": "1f6b7",
    "mens symbol": "1f6b9",
    "confetti ball": "1f38a",
    "crossed flags": "1f38c",
    "tanabata tree": "1f38b",
    "japanese dolls": "1f38e",
    "pine decoration": "1f38d",
    "carp streamer": "1f38f",
    "aerial tramway": "1f6a1",
    "mountain cableway": "1f6a0",
    "rowboat": "1f6a3",
    "ship": "1f6a2",
    "horizontal traffic light": "1f6a5",
    "speedboat": "1f6a4",
    "construction sign": "1f6a7",
    "vertical traffic light": "1f6a6",
    "triangular flag on post": "1f6a9",
    "police cars revolving light": "1f6a8",
    "clinking beer mugs": "1f37b",
    "beer mug": "1f37a",
    "baby bottle": "1f37c",
    "wind chime": "1f390",
    "school satchel": "1f392",
    "moon viewing ceremony": "1f391",
    "graduation cap": "1f393",
    "digit three + combining enclosing keycap": "33-20e3",
    "raised hand": "270b",
    "raised fist": "270a",
    "pencil": "270f",
    "victory hand": "270c",
    "k + r": "1f1f0-1f1f7",
    "postal horn": "1f4ef",
    "radio": "1f4fb",
    "television": "1f4fa",
    "videocassette": "1f4fc",
    "pager": "1f4df",
    "negative squared cross mark": "274e",
    "telephone receiver": "1f4de",
    "digit seven + combining enclosing keycap": "37-20e3",
    "cross mark": "274c",
    "newspaper": "1f4f0",
    "mobile phone with rightwards arrow at left": "1f4f2",
    "mobile phone": "1f4f1",
    "mobile phone off": "1f4f4",
    "vibration mode": "1f4f3",
    "antenna with bars": "1f4f6",
    "no mobile phones": "1f4f5",
    "camera": "1f4f7",
    "video camera": "1f4f9",
    "closed mailbox with lowered flag": "1f4ea",
    "open mailbox with raised flag": "1f4ec",
    "closed mailbox with raised flag": "1f4eb",
    "postbox": "1f4ee",
    "open mailbox with lowered flag": "1f4ed",
    "paperclip": "1f4ce",
    "round pushpin": "1f4cd",
    "straight ruler": "1f4cf",
    "satellite antenna": "1f4e1",
    "fax machine": "1f4e0",
    "cheering megaphone": "1f4e3",
    "public address loudspeaker": "1f4e2",
    "inbox tray": "1f4e5",
    "outbox tray": "1f4e4",
    "e-mail symbol": "1f4e7",
    "package": "1f4e6",
    "envelope with downwards arrow above": "1f4e9",
    "incoming envelope": "1f4e8",
    "name badge": "1f4db",
    "books": "1f4da",
    "memo": "1f4dd",
    "scroll": "1f4dc",
    "minidisc": "1f4bd",
    "briefcase": "1f4bc",
    "optical disc": "1f4bf",
    "floppy disk": "1f4be",
    "triangular ruler": "1f4d0",
    "ledger": "1f4d2",
    "bookmark tabs": "1f4d1",
    "notebook with decorative cover": "1f4d4",
    "notebook": "1f4d3",
    "open book": "1f4d6",
    "closed book": "1f4d5",
    "blue book": "1f4d8",
    "green book": "1f4d7",
    "orange book": "1f4d9",
    "digit zero + combining enclosing keycap": "30-20e3",
    "bar chart": "1f4ca",
    "pushpin": "1f4cc",
    "clipboard": "1f4cb",
    "A": "1f1e6",
    "B": "1f1e7",
    "C": "1f1e8",
    "D": "1f1e9",
    "E": "1f1ea",
    "F": "1f1eb",
    "G": "1f1ec",
    "H": "1f1ed",
    "I": "1f1ee",
    "J": "1f1ef",
    "K": "1f1f0",
    "L": "1f1f1",
    "M": "1f1f2",
    "N": "1f1f3",
    "O": "1f1f4",
    "P": "1f1f5",
    "Q": "1f1f6",
    "R": "1f1f7",
    "S": "1f1f8",
    "T": "1f1f9",
    "U": "1f1fa",
    "V": "1f1fb",
    "W": "1f1fc",
    "X": "1f1fd",
    "Y": "1f1fe",
    "Z": "1f1ff",
    "speaker": "1f508",
    "slightly frowning face": "1f641",
    "slightly smiling face": "1f642",
    "tram car": "1f68b",
    "double curly loop": "27bf",
    "shibuya 109 department store": "e50a"
  }

let parsedEmoji = {
    "fire for margurite": "https://cdn.jsdelivr.net/emojione/assets/3.0/png/64/1f525.png",
}
Object.keys(emoji).forEach(key => {
    parsedEmoji[key] = [baseUrl, emoji[key], ".svg"].join("")
})

export default parsedEmoji